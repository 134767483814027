import React from 'react';
import Challenge from './Challenge';
import './styles.css';
import {ChallengeDetails} from "../../interfaces/challenges";


interface ChallengesProps {
    challenges: ChallengeDetails[];
}

const Challenges: React.FC<ChallengesProps> = ({challenges}) => {
    return (
<div className="challenges">
    {challenges.map((challenge, index) => (
        challenge.logoUrl ? <Challenge key={index} challenge={challenge}/> : null
    ))}
</div>
    );
};

export default Challenges;