import React from 'react';
import {ChallengeDetails} from "../../interfaces/challenges";
import {format, differenceInDays, isAfter, isBefore} from "date-fns";
import {styled} from '@mui/material/styles';
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';

import FiberNewIcon from "@mui/icons-material/FiberNew";
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import TimelineIcon from '@mui/icons-material/Timeline';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const INVALID_DATE = "0001-01-01T00:00:00Z";

const commonIconStyles = {
    color: '#fc5201',
    fontSize: '1.45rem',
    width: '1rem',
    height: '1rem',
};

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

const formatDayMonth = (dateStr: string): string => {
    const date = new Date(dateStr);
    const day = format(date, 'do'); // 'do' will give you the day with the ordinal number
    const month = format(date, 'MMM'); // 'MMM' will give you the abbreviated month
    return `${day} ${month}`;
};

const formatYear = (dateStr: string): string => {
    const date = new Date(dateStr);
    return format(date, 'yyyy'); // 'yyyy' will give you the full year
};

const calculateProgressValue = (start: string, end: string): [number, string] => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const currentDate = new Date();

    if (isBefore(currentDate, startDate)) {
        // Current time is before start time
        return [100, '#37fc01'];
    } else if (isAfter(currentDate, endDate)) {
        // Current time is after end time
        return [100, '#fc0101'];
    } else {
        // Current time is between start and end times
        const totalDuration = differenceInDays(endDate, startDate);
        const elapsedDuration = differenceInDays(currentDate, startDate);
        const value = (elapsedDuration / totalDuration) * 100;
        return [value, '#8a00ff']; // Replace 'primary' with the color you prefer
    }
};

const evaluateDateStatus = (startDateStr: string, endDateStr: string): string => {
    const currentDate = new Date();
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    if (isBefore(currentDate, startDate)) {
        // Start date is in the future
        return 'Upcoming';
    } else if (isBefore(endDate, currentDate)) {
        // End date is in the past
        return 'Ends today';
    } else {
        // Current date is within the start and end dates (inclusive)
        const daysLeft = differenceInDays(endDate, currentDate) + 1; // Adding 1 to make it inclusive
        return `${daysLeft} day${daysLeft === 1 ? '' : 's'} left`;
    }
};

function getChallengeCategoryText(challengeCategory: string) {
    switch (challengeCategory) {
        case "run":
            return "Running activity";
        case "ride":
            return "Riding activity";
        case "swim":
            return "Swimming activity";
        case "any":
            return "Multiple activities";
        default:
            return "Segment activity";
    }
}

interface ChallengeProps {
    challenge: ChallengeDetails;
}

const Challenge: React.FC<ChallengeProps> = ({challenge}) => {
    const bgImageURL: React.CSSProperties = {
        '--background-image-url': `url(${challenge.logoUrl})`
    } as React.CSSProperties;

    const handleClick = () => {
        window.open(challenge.link, '_blank');
    };

    const isValidDate = (date: string) => date !== INVALID_DATE;

    const isNew = (): boolean => {
        const recordedDate = new Date(challenge.recordedAt);
        const currentDate = new Date();
        const differenceInDays = (currentDate.getTime() - recordedDate.getTime()) / (1000 * 3600 * 24);
        return differenceInDays <= 3;
    };

    const [value, color] = calculateProgressValue(challenge.startDate, challenge.endDate);

    return (
        <div onClick={handleClick} className="challenge" style={bgImageURL}>
            {isNew() && (
                <div className="newChallengeBadge">
                    <FiberNewIcon
                        sx={{
                            position: 'absolute',
                            top: '-3px',
                            right: '-2px',
                            color: '#fc5201',
                            fontSize: '1.45rem'
                        }}
                    />
                </div>
            )}
            <div className="card">
                <div className="header">
                    <img className="logo" src={challenge.logoUrl} alt={challenge.name}/>
                    <div className="title">{challenge.name}</div>
                    <div className="subtitle">{challenge.description}</div>
                </div>
                <div className="sep"></div>
                <div className="content">
                    <div className="activityType">
                        <ElectricBoltIcon sx={commonIconStyles}/>
                        <div className="description">
                            {getChallengeCategoryText(challenge.challengeCategory)}
                        </div>
                    </div>
                    {challenge.challengeType === "activity" ?
                        <>
                            <div className="goal">
                                <SportsScoreIcon sx={commonIconStyles}/>
                                <div className="description">{challenge.activityDetails?.description}</div>
                            </div>
                            <div className="goal">
                                <EmojiEventsIcon sx={commonIconStyles}/>
                                <div className="description">{challenge.activityDetails?.reward}</div>
                            </div>
                        </>
                        : null}
                    {challenge.challengeType === "segment" ?
                        <>
                            <div className="goal">
                                <TimelineIcon sx={commonIconStyles}/>
                                <div className="description">
                                    Complete {challenge.segmentDetails?.distance} distance.
                                </div>
                            </div>
                            {challenge.segmentDetails?.elevationGain !== "0 m" ?
                                <div className="goal">
                                    <ArrowUpwardIcon sx={commonIconStyles}/>
                                    <div className="description">
                                        {challenge.segmentDetails?.elevationGain} elevation gain.
                                    </div>
                                </div>
                                : null}
                        </>
                        : null}
                </div>

                <div className="footer">
                    <div className="progress">
                        <BorderLinearProgress
                            variant="determinate"
                            value={value}
                            sx={{
                                height: '40px',
                                margin: '-10px',
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: color,
                                    '-webkit-mask-image': 'linear-gradient(to bottom, transparent, black)',
                                    'mask-image': 'linear-gradient(to bottom, transparent, black)'
                                }
                            }}
                        />
                    </div>
                    {isValidDate(challenge.startDate) && isValidDate(challenge.endDate) && (
                        <div className="date">
                            <div className="startDate">
                                <div className="dateContainer">
                                    <div className="dayMonthContainer">{formatDayMonth(challenge.startDate)}</div>
                                    <div className="yearContainer">{formatYear(challenge.startDate)}</div>
                                </div>
                            </div>
                            <div className="challenge-status">
                                {evaluateDateStatus(challenge.startDate, challenge.endDate)}
                            </div>
                            <div className="endDate">
                                <div className="dateContainer">
                                    <div className="dayMonthContainer">{formatDayMonth(challenge.endDate)}</div>
                                    <div className="yearContainer">{formatYear(challenge.endDate)}</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        return '';
    }
    return format(date, 'MMM d, yyyy');
};

export default Challenge;
