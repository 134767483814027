import React, {ReactNode} from 'react';
import Button from '@mui/material/Button';
import './styles.css';
import {ChallengeDetails} from "../../interfaces/challenges";
import Badge from '@mui/material/Badge';

interface HeaderProps {
    onSelect: (type: 'upcoming' | 'active' | 'new') => void;
    newChallenges: ChallengeDetails[];
    themeSwitch: ReactNode,
}

const Header: React.FC<HeaderProps> = ({onSelect, newChallenges, themeSwitch}) => {
    return (
        <div className="appHeader">
            <div className="themeToggle">{themeSwitch}</div>
            <div className="brandName">
                <img className="logo" alt="alpharise-logo" src="alpharise_small_logo.png" />
                <div>
                    AlphaRise
                    <div className="tagline">Elevate Your Stride</div>
                </div>
            </div>
            <div className="menu">
                {newChallenges && newChallenges.length > 0 ?
                    <Badge color="primary" badgeContent={
                        <span
                            style={{fontSize: '0.5rem'}}>{newChallenges.length}
                        </span>}>
                        <Button
                            sx={{
                                fontSize: '0.625rem'
                            }}
                            onClick={() => onSelect('new')}
                            variant="outlined"
                            size="small">
                            new
                        </Button>
                    </Badge> :
                    <Button
                        sx={{
                            fontSize: '0.625rem'
                        }}
                        onClick={() => onSelect('new')}
                        variant="outlined"
                        size="small"
                        disabled>
                        new
                    </Button>
                }
                <Button
                    onClick={() => onSelect('upcoming')}
                    variant="outlined"
                    size="small"
                    sx={{
                        fontSize: '0.625rem'
                    }}>
                    upcoming
                </Button>
                <Button
                    onClick={() => onSelect('active')}
                    variant="outlined"
                    size="small"
                    sx={{
                        fontSize: '0.625rem'
                    }}>
                    active
                </Button>
            </div>
        </div>

        //     "strava_id": "4085",
        // "challenge_type": "activity",
        // "challenge_status": "active",
        // "name": "World Champion XCM Challenge - Henrique Avancini",
        // "description": "Pedale a mesma distância, que o campeão mundial, Henrique Avancini, pedalou no World Champion XCM",
        // "logo_url": "https://dgalywyr863hv.cloudfront.net/challenges/4085/4085-logo.png",
        // "link": "https://www.strava.com/challenges/4085",
        // "segment_details": null,
        // "activity_details": {
        // "description": "Pedale 94,5k em um única atividade.",
        // "type": "streak",
        // "qualifying_activities": [
        // "Ride",
        // "Virtual Ride"
        // ],
        // "reward": "Receba um badge digital exclusivo do desafio World Champion XCM - Henrique Avancini."
        // },
    );
};

export default Header;
