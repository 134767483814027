import CircularProgress from '@mui/material/CircularProgress';
import React from "react";
import './styles.css';

const Loading: React.FC = () => {
    return (
        <div className="circularProgress">
            <CircularProgress color="inherit" />
        </div>
    );
};

export default Loading;
