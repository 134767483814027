import React from 'react';
import Challenges from './pages/Challenges/Challenges';
import Header from './pages/Header/Header';
import Loading from './pages/Loading/Loading';
import {QueryClient, QueryClientProvider} from "react-query";
import {ThemeProvider as MuiThemeProvider} from '@mui/material/styles';
import {CssBaseline, styled, Switch} from "@mui/material";
import {useEffect, useState} from "react";
import {darkTheme, lightTheme} from "./themes";
import {ChallengeDetails, ChallengesData, ChallengesResponse} from "./interfaces/challenges";
import {camelizeKeys} from "humps";
import { parseISO, isAfter, isBefore, startOfToday, subDays } from 'date-fns';

const queryClient = new QueryClient();

export const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62 * 0.8,
    height: 34 * 0.8,
    padding: 7 * 0.8,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(4.8px)', // 6px * 0.8
        '&.Mui-checked': {
            color: theme.palette.primary.main,
            transform: 'translateX(17.6px)', // 22px * 0.8
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    theme.palette.primary.main,
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.secondary.main,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.background.default,
        width: 32 * 0.8,
        height: 32 * 0.8,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                theme.palette.primary.main,
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 16, // 20 / 2 * 0.8
    },
}));


function App() {
    const [darkMode, setDarkMode] = useState(false);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    const [selectedType, setSelectedType] = useState<'upcoming' | 'active' | 'new'>('upcoming');
    const [challengesData, setChallengesData] = useState<ChallengesData>({
        active: [],
        upcoming: [],
        new: [],
    });
    const [isLoading, setLoading] = useState<boolean>(true); // Add loading state

    const threeDaysAgo = subDays(new Date(), 3);

    useEffect(() => {
        setLoading(true);
        fetch('https://strava-data.alpharise.app/data.json')
            .then(response => response.json())
            .then(data => camelizeKeys(data))
            .then(data => {
                const camelizedData = data as ChallengesResponse;
                const today = startOfToday();

                const sortedChallenges = camelizedData.challenges.reduce((acc, challenge) => {
                    const startDate = parseISO(challenge.startDate);
                    const recordedAt = parseISO(challenge.recordedAt);

                    if (isBefore(startDate, today)) {
                        acc.active.push(challenge);
                    } else if (isAfter(startDate, today)) {
                        acc.upcoming.push(challenge);
                    }

                    if (isAfter(recordedAt, threeDaysAgo)) {
                        acc.new.push(challenge);
                    }

                    return acc;
                }, { active: [], upcoming: [], new: []} as ChallengesData);

                setChallengesData({
                    active: sortChallengesByStartDate(sortedChallenges.active),
                    upcoming: sortChallengesByStartDate(sortedChallenges.upcoming),
                    new: sortChallengesByStartDate(sortedChallenges.new),
                });
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);

    const handleSelect = (type: 'upcoming' | 'active' | 'new') => {
        setLoading(true);
        setSelectedType(type);
        setLoading(false);
    };

    return (
        <MuiThemeProvider theme={darkMode ? darkTheme : lightTheme}>
            <QueryClientProvider client={queryClient}>
                <CssBaseline/>
                <Header
                    onSelect={handleSelect}
                    themeSwitch={<MaterialUISwitch theme={darkMode ? darkTheme : lightTheme} sx={{ m: 1, margin: 0 }} onClick={toggleDarkMode} />}
                    newChallenges={challengesData.new}
                />
                {isLoading ? (
                    <Loading />
                ) : (
                    <Challenges challenges={challengesData[selectedType]} />
                )}
            </QueryClientProvider>
        </MuiThemeProvider>
    );
}

function sortChallengesByStartDate(challenges: ChallengeDetails[]): ChallengeDetails[] {
    return challenges.sort((a, b) => {
        const dateA = new Date(a.startDate);
        const dateB = new Date(b.startDate);
        return dateB.getTime() - dateA.getTime();
    });
}


export default App;
